import type { StyleRule } from '@archipro-design/aria';
import {
    Grid,
    Header,
    Label,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';

import * as S from './PageLayout.style';

const PageSection: React.FC<
    React.PropsWithChildren<{ sectionTitle?: string; style?: StyleRule }>
> = ({ sectionTitle, children, style }) => {
    const { css } = useStyles();
    const { mobile: isMobile } = useAppDisplayModeContext();

    return (
        <Grid columns={1} className={css(style ?? S.Section)}>
            {sectionTitle && isMobile ? (
                <Label
                    variant="01"
                    weight="regular"
                    className={css(S.SectionTitle)}
                >
                    {sectionTitle}
                </Label>
            ) : (
                <Header variant="03" as="h3" className={css(S.SectionTitle)}>
                    {sectionTitle}
                </Header>
            )}
            {children}
        </Grid>
    );
};

export default PageSection;
